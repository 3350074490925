import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import NotAvailable from './components/NotAvailable'
import { createClient } from '@supabase/supabase-js'
import { SessionContextProvider } from '@supabase/auth-helpers-react';

const supabase = createClient(
  "https://zbhtflbnppfftfjhntal.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InpiaHRmbGJucHBmZnRmamhudGFsIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTQzMDYyMjAsImV4cCI6MjAwOTg4MjIyMH0.dw_ByHkQEtxtcItqGHnxCeBawLRO961i_tF2z9AwvOQ"
)


const { countries, zones } = require("moment-timezone/data/meta/latest.json");
const timeZoneToCountry = {};

Object.keys(zones).forEach(z => {
  timeZoneToCountry[z] = countries[zones[z].countries[0]].name;
});

console.log(JSON.stringify(timeZoneToCountry, null, 2))

var userRegion;
var userCity;
var userCountry;
var userTimeZone;

if (Intl) {
  userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  var tzArr = userTimeZone.split("/");
  userRegion = tzArr[0];
  userCity = tzArr[tzArr.length - 1];
  userCountry = timeZoneToCountry[userTimeZone];
}

console.log("Time Zone:", userTimeZone);
console.log("Region:", userRegion);
console.log("City:", userCity);
console.log("Country:", userCountry);
//alert(`${userCity} ${userRegion} ${userCountry}`)
// <React.StrictMode></React.StrictMode>

  ReactDOM.render(
    <SessionContextProvider supabaseClient={supabase}> 
      {userCountry.toLowerCase() == "united states" && <App />}
      {userCountry.toLowerCase() != "united states" && <NotAvailable />}
    </SessionContextProvider>,
    document.getElementById('root')
  );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();