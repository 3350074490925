import React, { useState, useEffect, useRef } from 'react';
import ActivityItem from './ActivityItem';


const ActivityOptions = (props) => {
    const [ open, setOpen ] = useState(false);
    const [ selectedId, setSelectedId ] = useState(0);
    const [callbackQueue, setCallbackQueue] = useState(null);
    const [todaysDate, setTodaysDate] = useState(null);
    const [nextWeeksDate, setNextWeeksDate] = useState(null);

    const style = {
        display: 'block',
        left: 0,
        top: 0,
        color: "lightgrey",
        zIndex: 1,
        marginTop: 50,
        paddingTop: 50,
        fontSize: 20
    }

    const iconStyle = {
        backgroundColor: "transparent",
        maxWidth: 20,
        maxHeight: 20,
        borderRadius: "50%",
        padding: 12,
        paddingLeft:10,
        paddingRight: 10,
        marginRight: 10
    }
    
    const getSelectedId = (key, callback) => {
        setSelectedId(prevState => {
          callback(key);
          return key;
        });
      };

    useEffect(() => {
        // get todays date and store it into state
        let today = new Date();
        let dd = today.getDate();
        let mm = today.getMonth()+1; //January is 0 so need to add 1 to make it 1!
        let yyyy = today.getFullYear();

        // get the month name of the current month
        let monthName = new Intl.DateTimeFormat('en-US', { month: 'long'}).format(today);

        setTodaysDate(`${monthName} ${dd}, ${yyyy}`);

        // get the date of next week
        let nextWeek = new Date(today.getTime() + 7 * 24 * 60 * 60 * 1000);
        let nextWeekdd = nextWeek.getDate();
        let nextWeekmm = nextWeek.getMonth()+1; //January is 0 so need to add 1 to make it 1!
        let nextWeekyyyy = nextWeek.getFullYear();

        // get the month name of the current month
        let nextWeekMonthName = new Intl.DateTimeFormat('en-US', { month: 'long'}).format(nextWeek);

        setNextWeeksDate(`${nextWeekMonthName} ${nextWeekdd}, ${nextWeekyyyy}`);
    });

    return (
        <div style={style}>
        <ActivityItem key="0" dataKeyId="0" getSelectedId = {getSelectedId} selectedId={selectedId}  callChatAPI={props.callChatAPI} 
            statement={`Today is ${todaysDate}. Give me a dashboard of info below:
            Put this information STRICTLY into a table format below that fits a text message:
            
            First pull up information about me that is relevant to my endeavors and my life.
            Then having that information try to break that up into items as shown below in the item list.
            
            Show me a dashboard of everything going on in my life. Compare me to a person who has hit the peak of each category.

            address these categories: my financial status, my startup progress, My career status, my schedule, rsearched stocks, family improvement, my educational progress. 
            
            what I need to know given current circumstances to do in my business.
            My "score" should be between -100% to 100% compared to best person in that category. A score of 0 is the average. So don't be easy to give a high score. Be critical on the score. Don't make it easy. Give a 0 if there is no data. But only use FACTS. don't make stuff up. This should put into a format this to read easy and clean.
            w

            Get the financial summary
            get the stock data
            Get the stock research
            Get family messages
            Get my current schedule
            Get my company's update.
            Get my financial networth
            `} >
            <span style={iconStyle}>〰</span> Dashboard
        </ActivityItem>
        <ActivityItem key="0" dataKeyId="0" getSelectedId = {getSelectedId} selectedId={selectedId}  callChatAPI={props.callChatAPI} 
            statement={`Today's date is ${todaysDate}. Give me a dashboard of research below:
            
            Find RESEARCH AND NEWS

            First get information about me that pertains to my endeavors and my life.
            Then take those endeavors and go rsearch how I could make this better. Basically take me to the next step or next level.
            Take all of the important things you know about me; get the most important items that I currently care about as of late. Then take that information and research online how I can make it better. Find any news, articles, websites or people and figure out what I need to do to improve my current life to make progress.
            
            Provide a response that mirrors the data below:
            i.e.:
            ""
            **Offering**: Helps you with x and solve y
            **problem**: Helps with x problem you're having

            ""

            Replace this example with real data about me. 

            `} >
            <span style={iconStyle}>📰</span> News
        </ActivityItem>
        <ActivityItem key="1" dataKeyId="1" getSelectedId = {getSelectedId} selectedId={selectedId}  callChatAPI={props.callChatAPI} 
            statement={`My Schedule
            First get my schedule from previous message or fetch it.
            Then look through my schedule/calendar to tell me what looks interesting and relevant to what's going on now.
            Get my current schedule for this coming week between ${todaysDate} and ${nextWeeksDate} and include any details if any. If I have nothing today or this week, then say you have nothing. But focus on real data of what I have to do first. Give context if any and how I could do any of these task or events better. Show things that are unique. Consolidate information so things are redundant, this way novel things stick out. Don't just give a calendar.
            
            `} >
            <span style={iconStyle}>📆</span> Schedule
        </ActivityItem>
        <ActivityItem key="2" dataKeyId="2" getSelectedId = {getSelectedId} selectedId={selectedId}  callChatAPI={props.callChatAPI} 
            statement='Financial Status:  Give me a dashboard of info below:

            First thing is to get my financial records and data.
            Then I need you to get the any information on my bank transactions.
            Then i need you to evaluate every bit of my finances to help me see where I am for my age.
            Show me these items:

            cash flow
            income this month
            expenses this month
            total income
            total expenses
            expenses by category
        
            Look at my personal finances and construct a table of where my money is and how I can make improvements. 

            Replace this example with real data about me. Give me my financial summary status update. Basically get all of income, transactions, investments, etc and give me a status on my inances right now. Tell me how my financial home and budget is looking. Then provide some ways to help make more money.
            '>
            <span style={iconStyle}>💰</span> Financial
        </ActivityItem>
        <ActivityItem key="3" dataKeyId="3" getSelectedId = {getSelectedId} selectedId={selectedId}  callChatAPI={props.callChatAPI} 
            statement='Profitability:  Give me a dashboard of my Startup:
            
            First bring up all information about my company or startup.
            Then evaluate how my company has been performing over time from previous updates.
            Then break down all of the aspects of this startup with the list below.
            Look deep into my company from things I mentioned and what you can find. We need to make my startup profitable. Look into my startup and pull our everything about it: customers, revenue, expenses, traction, etc. Then once you know that figure out if I have made any progress over time, especially recently.
            
            ""
            "STATUS"
            Overall Progress
            Projected

            current revenue
            current MRR
            projected revenue next month
            current ARR
            what is annual COGS
            
            overall cost
            CAC Cost in $ and improvement
            # customers
            churn
            customer growth

            Profit EBITA
            ""

            Replace this example with real data about me. Use the example above and fill in the real data for me and my real startup revenue and financials. this is not personal income but business income and expenses. Try to find this data and compute every piece even the projections.
            '>
            <span style={iconStyle}>🚀</span> Startup
        </ActivityItem>
        <ActivityItem key="6" dataKeyId="6" getSelectedId = {getSelectedId} selectedId={selectedId}  callChatAPI={props.callChatAPI} 
            statement='My Career:  Give me a dashboard of info below:
            
            You know my working condition. Provide me with support to help me progress in my career.
            1. I need you to analyze my current career position my current job and the circumstances.
            2. Then I need you to evaluate the best next logical step either from what I said or what you think.
            3. Now provide resources to support what I should be doing.

            Provide the response in the format as shown below:

            ""
            Tasks:
            Next Offering: 
            New Annual Pay:
            Location:
            Position Title:
            People to Connect with:
            Technology to learn:
            ""        

            Provide every aspect of the skill or course that I need to take right now that will increase my overall score and look good in my career. Be mindful of my schedule, work and aspect of my career that this will help.
            '>
            <span style={iconStyle}>⚡</span> Career
        </ActivityItem>
        <ActivityItem key="2" dataKeyId="2" getSelectedId = {getSelectedId} selectedId={selectedId}  callChatAPI={props.callChatAPI} 
            statement='Networth Improvement:  Give me a dashboard of info below:
            First get all of my invests, cash, transactions and loans and debt.
            Then evaluate this information to see if my total networth for my age.
            Then break down my financials into the items listed below. I need to see where I am compared to most people.
            Look at my personal finances and do anything possible that is legal to help me double my net worth. Look at my money and current cash in bank to answer this.
            
            current income
            assets
            loans
            investments
            student loans
            credit card
            expected networth at age x: 
            current networth at age x:

            Total Networth
            expected networth by age 65:

            to improve your networth you need to
            ""

            Replace this example with real data about me. Get the data from my financial summary or data to fill in this information if possible shown above. Be thorough and after showing the data, explain how i can improve my networth right away for my age.
            '>
            <span style={iconStyle}>💎</span> Networth
        </ActivityItem>
        <ActivityItem key="4" dataKeyId="4" getSelectedId = {getSelectedId} selectedId={selectedId}  callChatAPI={props.callChatAPI} 
            statement="I need you to rsearch the stock market and give me any stock positions that will do well in the next few weeks to maybe the next month or so. I am looking to invest money that will grow my investment portfolio.">
            <span style={iconStyle}>💹</span> Investing
        </ActivityItem>
        <ActivityItem key="5" dataKeyId="5" getSelectedId = {getSelectedId} selectedId={selectedId}  callChatAPI={props.callChatAPI} 
            statement="I need investors now who can help with funding my startup and help build out my company. I need you to package up the investor, the expected amount and the pitch that will most likely lock in the investment. If I have investments or currently working on investments, give me status on that. Be specific of how to aquire that funding. Any tactic possible.">
            <span style={iconStyle}>👨🏼‍🔬</span> Investors
        </ActivityItem>
        <ActivityItem key="7" dataKeyId="7" getSelectedId = {getSelectedId} selectedId={selectedId}  callChatAPI={props.callChatAPI} 
            statement='Family Improvement:  Give me a dashboard of info below:
            First collect information about my family anything you know based on the closest people to me.
            Then look at all the things I am doing with them.
            No Evaluate my interaction and if my interaction is improving as of late.
            To keep a balanced life and knowing my current work conditions, today you have to help me make the best actions to make my closest loves feel loved and what can I do for them? Be specific knowing you know my closest family members and closest friends.

            Replace this example with real data about me. The "improvement" is what I have did or need to do. The "score" is how I am doing with this person. "Family Member" is the name of the person in my life such as spouse name or kid name or friend name or parent name or S.O. name.
            '>
            <span style={iconStyle}>👪</span> Family
        </ActivityItem>
        <ActivityItem key="8" dataKeyId="8" getSelectedId = {getSelectedId} selectedId={selectedId}  callChatAPI={props.callChatAPI} 
            statement='Courses and Education:
            
            First I need you to get all of my educational background of what I have done.
            Then I need you look at my current educational condition and evaluate if it need works.
            If I am in college (look at my past messages or data) to let me know. Or look me up.
            Do everything that you can to help get through these courses so I pass with top grades and the help me with a high paying job relevant to what what is best.
            
            Tell me about an education that I can learn or start learning to improve my skills or get through college.

            Skill:
            Why it matters:
            Where can I use it:
            What company needs it:
            How long it takes to learn:
            How much does it cost:
            Link to website of course or tool:
            Best person to contact:
            '>
            <span style={iconStyle}>👨🏼‍🎓</span> College
        </ActivityItem>
      </div>
    );
};


export default ActivityOptions;