import React, { useState, useEffect, useRef } from 'react';
import ThreadFormat from './ThreadFormat';
import copy from 'copy-to-clipboard';
import './App.css';
import packageJson from '../../../package.json';
import axios from 'axios';
import { reference } from '@popperjs/core';

const api = axios.create({ "baseURL": packageJson.proxy });

const Thread = (props) => {
    let styleBlock = {
        position: "relative"
    }

    let profile = {

    }

    const [isHovered, setHovered] = useState(false);

    const handMouseEnter = () => {
        setHovered(true);
    }

    const handleMouseLeave = () => {
        setHovered(false);
    }

    let style = {
        backgroundColor: props.currentUser ? "#333541" : "#2a2b2f",
        borderRight: props.currentUser ? "8px solid #444791 " : "1px solid transparent",
        borderLeft: props.currentUser ? "1px solid transparent " : "8px solid #585b61",
        padding: 10,
        paddingTop: 20,
        paddingLeft: 20,
        bottom: 400,
        marginBottom: 20,
        maringTop: 15,
        borderRadius: 12,
        left: props.currentUser ? "unset" : "calc(50% - 300px)",
        right: props.currentUser ? "calc(50% - 300px)" : "unset",
        maxWidth: "600px",
        minWidth: "600px",
        alignSelf: "flex-start",
        marginLeft: props.currentUser ? "calc(50% - 300px)" : "calc(50% - 300px)"
    }

    if (props.isMobile) {
        style.marginLeft = "5%";
        style.maxWidth = "90%";
        style.minWidth = "90%";
    }

    const buttonStyle = {
        position: "absolute",
        top: 0,
        right: 1,
        padding: "2px 4px", 
        margin: 0,
        borderRadius: 4,
        backgroundColor: "#585b61",
        color: "#ffffff",
        fontSize: 12,
        display: "flex",
        flexDirection: "column", // Added this to stack elements vertically
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        verticalAlign: "middle",

        tip: {
            position: "absolute",
            left: -200,
            minWidth: 400,
            transform: 'transform(-50%)',
            padding: '5px',
            backgroundColor: '#333',
            color: "#fff",
            borderRadius: "20px",
            opacity: isHovered ? 1 : 0,
            ponterEvents: isHovered ? 'auto' : 'none',
            transition: 'opacity 0.3s ease-in-out'
        }
    }


    const ellipsisExtensiion = {
        position: "absolute",
        top: 0,
        right: 1,        
        paddingLeft: "4px",
        paddingRight: "4px",
        paddingBottom: "4px", 
        margin: 0,
        borderRadius: 4,
        backgroundColor: "#585b61",
        color: "#ffffff",
        fontSize: 12,

        tip: {
            position: "absolute",
            right: 0,
            minWidth: 150,
            top: 30,
            padding: '10px',
            backgroundColor: '#111214',
            color: "#fff",
            borderRadius: "4px",
            opacity: isHovered ? 1 : 0,
            ponterEvents: isHovered ? 'auto' : 'none',
            transition: 'opacity 0.3s ease-in-out',

            button: {
                padding: "5px 10px",
                borderRadis: 5
            }
        }
    }

    const dateFont = {
        color: "grey",
        fontSize: 11,
        marginLeft: 10
    }

    const topicFont = {
        color: "grey",
        fontSize: 12,
        marginLeft: 10
    }

    const userNameFont = {
        fontSize: 14,
        marginLeft: 10
    }
    
    function getFirstLetters(inputString) {
        let words = inputString.split(' ');
        let outputString = '';
        for(let i = 0; i < words.length; i++) {
            if(words[i] !== '') {
                outputString += words[i][0].toUpperCase();
            }
        }
        return outputString;
      }
    
    function getProfile(text) {
        if (text !== undefined) {
            return getFirstLetters(text)
        }

        if (localStorage['user'] == undefined) {
            return getFirstLetters(props.user)
        }
        else
        {
            const user = JSON.parse(localStorage['user']);
            return <img src={user.photoURL} style={{ position: "absolute", top: 0, right: 0, width: 45, height: 45, borderRadius: "50%", backgroundColor: "#fff", color: "#444791", textAlign: "center", fontSize: 12, fontWeight: "bold" }} />
        }
    }

    function getTime(property) {
        const now = new Date();
    
        if (!property) {
            return now.toLocaleTimeString();
        }
        
        const ttimestamp = new Date(property);
    
        if (ttimestamp.toDateString() === now.toDateString()) {
            if (props.isMobile) {
                return `${ttimestamp.toLocaleDateString(undefined, { dateStyle: 'short' })} ${ttimestamp.toLocaleTimeString(undefined, { timeStyle: 'short' })}`;
            }
            else 
            {
                return `${ttimestamp.toLocaleDateString(undefined, { dateStyle: 'full' })} ${ttimestamp.toLocaleTimeString(undefined, { timeStyle: 'short' })}`;
            }
        }
        else 
        {
            if (props.isMobile) {

                return `${ttimestamp.toLocaleDateString(undefined, { dateStyle: 'short' })} ${ttimestamp.toLocaleTimeString(undefined, { timeStyle: 'short' })}`;
            }
            else 
            {
                return `${ttimestamp.toLocaleDateString(undefined, { dateStyle: 'full' })} ${ttimestamp.toLocaleTimeString(undefined, { timeStyle: 'short' })}`;
            }
        }
    }

    function open(e) {

    }

    function copyMe(e) {
        let copyMessage = props.chat.messageBase !== undefined && props.chat.messageBase.trim() != "" ? props.chat.messageBase: props.chat.message;
        console.log(props.chat);
        copy(copyMessage)
        e.target.innerHTML = "Copied!"
        e.target.style.backgroundColor = "#4CAF50";
        
        setTimeout(() => {
            e.target.innerHTML = "Copy text"
            e.target.style.backgroundColor = "#585b61";
        }, 2000);
    }

    const user = JSON.parse(localStorage['user'])

    function deleteMe(e) {    // Replace 'api' with the actual API call function.
        e.target.innerHTML = "Deleting!"
        e.target.style.backgroundColor = "#d86262";
        
        api({
          method: 'post',
          url: `/main-chat-delete?id=${props.chat.id}&uid=${user.uid}`,
          headers: { 
            'Accept': 'Token',
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization',
          },
          data: { id: props.chat.id, uid: user.uid },

        })
        .then((response) => {
            console.log(response);

            if (response.data) {
                props.handleUpdateChat();
            }
            document.querySelector(".chat-window-section").scrollTop = document.querySelector(".chat-window-section").scrollHeight;
        })
        .catch((e) => {} );
    }
    
 

    return (
        <div className={"message thread-" + props.index} style={styleBlock} key={props.index}>
            <div style = {profile}>

            </div>
            <div style ={style}>
                <div style={{ fontSize: 12, marginBottom: 5, fontWeight: "bold", minWidth: "fit-content", position: "relative" }}>
                    <div style={{ display: "inline-flex"}}>
                        <div style={{ paddingTop: 10, width: 45, height: 45, borderRadius: "50%", backgroundColor: "#fff", color: "#444791", textAlign: "center", fontSize: 16, fontWeight: "bold", position: "relative" }} >
                            {props.chat.isUser && getProfile()}
                            {!props.chat.isUser && getProfile(props.chat.topic)}
                            
                        </div>
                        <div>
                            <div style={userNameFont}>
                                {props.user}
                            </div>
                            <div style =  {topicFont}>
                                {props.chat.topic}
                            </div>
                            <div style={dateFont}>
                                {getTime(props.timestamp)}
                            </div>
                        </div>
                    </div>
 
                    
                    {/* <button onClick={copyMe} style={{ position: 'absolute', right: '10px', top: '0px', backgroundColor: '#585b61', color: 'white', padding: '2px 8px', borderRadius: '4px', border: 'none', cursor: 'pointer' }}>Copy text</button> */}
                    <div style={ellipsisExtensiion} onMouseEnter={handMouseEnter} onMouseLeave={handleMouseLeave}>
                            . . .
                        <div style={ellipsisExtensiion.tip}>
                            <div style={ellipsisExtensiion.tip.button} onClick={copyMe}>Copy Text</div>
                            <div style={ellipsisExtensiion.tip.button} onClick={deleteMe}>Delete</div>
                        </div>
                    </div>
                </div>
                     {ThreadFormat(props.chat.message, props.chat.title, props.index, props.addJob, props.user, props.isMobile)}
            </div>
        </div>
    );
  };
  

  export default Thread;