import React, { useState, useEffect, useRef } from 'react';
import JobSection from './JobSection';

const RightPane = (props) => {

    let style = {
        display: props.showJobsMobile ? 'block' : 'none'
    }

    if (!props.isMobile) {
      style = {
          display: 'block'
      }
  }
  
    return (
      <div className="rightPane"style={style}>
          <div className='right-title'>Dashboard</div>
          <div>
          {props.jobs.map((job, index) => (
            <div key={index} className="job-main">
              <JobSection job={job} index={index} />
            </div>
          ))}
          </div>
      </div>
    );
  };
  
  
  export default RightPane;