import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react';

import packageJson from '../../../package.json';

const api = axios.create({ "baseURL": packageJson.proxy });

const MessageBox = (props) => {

    const [ message, setMessage ] = useState('')
    const [ loading, setLoading ] = useState(false)
    const [ leftLoading, setLeftLoading ] = useState(0)
    const [ messageWait, setmessageWait ] = useState('')
    const [ textboxValue, setTextboxValue ] = useState('')
    const [ textboxStyle, setTextboxStyle ] = useState(20)
    const [ hitEnterCount, setHitEnterCount ] = useState(0)

    const inputRef = useRef(null);

    const username = props.user;

    const messageWaitList = [
        "Wait a moment",
        "This can take a minute",
        "Cofounder AI is thinking",
        "Thinking " +  username,
        "Cofounder is typing",
        "refresh after 3 minutes go by",
        username + " I'm collecing info",
        "Okay packaging it up now",
        "Give me a moment to review"
    ]

    useEffect(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, []);

    const calculateTextboxRows = () => {
      const textboxWidth = inputRef.current.offsetWidth;
      const maxCharacterWPerRow = Math.floor(textboxWidth / 13); // Average character width
      const numRows = Math.ceil(textboxValue.length / maxCharacterWPerRow) || 1;
    
      // Set a minimum height or calculate based on rows
      let textboxHeight = Math.max(numRows * 20, 70); // 20px per row, 50px minimum

      let windowLength = document.querySelector(".centerPane")?.clientWidth;
      let windowMessageLength = document.querySelector(".messageText")?.clientWidth;
      let left = (windowLength - windowMessageLength)/2 - 10;

      setLeftLoading(left)

      setTextboxStyle(textboxHeight);
    };

    useEffect(() => {
      calculateTextboxRows();
      window.addEventListener('resize', calculateTextboxRows);

      return () => {
        window.removeEventListener('resize', calculateTextboxRows);
      };
    }, [textboxValue]);


    // Save state when API call initiated
    function onApiCallInitiated(apiDetails) {
        localStorage.setItem('api_in_progress', 'true');
        localStorage.setItem('api_details', JSON.stringify(apiDetails));
        // Your API call logic here
    }

    // Listen for visibility changes
    document.addEventListener('visibilitychange', function() {
        const apiDetails = JSON.parse(localStorage.getItem('api_details'));
        if (document.visibilityState === 'visible' && apiDetails) {
          checkAppState();
        }
    });

    // Check saved state and resume API call
    function checkAppState() {
        const apiInProgress = localStorage.getItem('api_in_progress');
        
        const apiDetails = JSON.parse(localStorage.getItem('api_details'));
        if (apiInProgress === 'true') {
            updateUI();
        }
        else 
        {
            resumeApiCall(apiDetails);
        }
    }

    // Resume API call
    function resumeApiCall(apiDetails, timeoutId) {

        if (timeoutId !== undefined) {
            clearTimeout(timeoutId);
        }

        // Your logic to resume the API call
        // After completion, remove the saved state
        localStorage.setItem('api_in_progress', 'false');
        setLoading(false);

        const apiDetailscheck = JSON.parse(localStorage.getItem('api_details'));
        if (apiDetailscheck) {
            props.handleUpdateChat();
        }
        
        localStorage.removeItem("api_details");
    }

    // Update UI
    function updateUI() {
        // Update UI elements based on restored state
        if (localStorage.getItem('api_in_progress') === 'true') {
          // Show loading spinner
          setLoading(true);
        }
    }



    let windowLength = document.querySelector(".centerPane")?.offsetWidth;
    let bottomBoxMargin = 10;


    let style = {
       bottom: -30
    }

    let button = {
       bottom: 61,
       right: 25
    }

    if (props.phoneType() === 'iPhone') {
        style.bottom = 0;
        button.bottom = 31;
        button.right = 15;
        bottomBoxMargin = 0;
    }

    if (props.phoneType() === 'iPhoneBrowser') {
        style.bottom = 10;
        button.bottom = 31;
        button.right = 15;
        bottomBoxMargin = 0;
    }

    if (props.phoneType() === 'Android') {
      style.bottom = 0;
      button.bottom = 31;
      button.right = 15;
      bottomBoxMargin = 0;
    }

    if (props.phoneType() === 'AndroidBrowser') {
      style.bottom = 0;
      button.bottom = 31;
      button.right = 15;
      bottomBoxMargin = 0;
    }


    const boxStyle = {
      minHeight: textboxStyle,
      left: 0,
      display: "flex",
      flexDirection: "column", // Added this to stack elements vertically
      justifyContent: "center",
      alignItems: "center"
    }

    // if (windowLength > 1096) {
    //     left = `calc(50% - ${windowMessageLength / 2 - 120}px)`;
    // }

    const box = {
      position: "absolute",
      marginLeft: 0,
      marginRight: 0,
      marginTop: bottomBoxMargin,
      left: leftLoading
    }

    const loadingStyle = {
      height: 40,
      textAlign: 'center',
      left: "calc(10% - 140px)",
      position: 'absolute',
      top: -5
    }


    function callChatAPI(newMessage, e) {  
      // if (e != null && e.target.value != null && e.target.value == '') {
      //     return;
      // }

      setLoading(true);
      setMessage(newMessage);
      let index = 0;
      let timeoutId;
      
      function executeInterval() {
        let time = Math.ceil(Math.random() * 10) * 1000;
        setmessageWait(messageWaitList[index]);
        index = (index + 1) % messageWaitList.length;
      
        timeoutId = setTimeout(executeInterval, time);
      }
      
      // Initial call to start
      timeoutId = setTimeout(executeInterval, Math.ceil(Math.random() * 2) * 1000);      


      props.updateChatMessages(true, newMessage, props.user);
      document.querySelector(".chat-window-section").scrollTop = document.querySelector(".chat-window-section").scrollHeight;
      document.querySelector(".thinking").classList.add("pulse");
      document.querySelector(".thinking").style.display = "inline-flex";

      let messageBlocks = props.messages;
      let messages = [];

      if (props.user && props.user != "") {
          let u = [ "[username]"];
          messages.push([ ...u, ...messageBlocks.map( x => x.message), message ]);
      }
      else 
      {
          messages =  [ ...messageBlocks.map( x => x.message), message ];
      }

      messages = [ message ];

      setTextboxValue('');

      if (e != null && e.target.value != null) {
        e.target.value = '';
      }

      const user = JSON.parse(localStorage['user'])
      onApiCallInitiated(newMessage)

      // Replace 'api' with the actual API call function.
      api({
        method: 'post',
        url: `/main-chat?username=${username}&messages=${messages.join('|')}&uid=${user.uid}`,
        headers: {
            'Accept': 'Token',
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization',
        },
        data: { messages: messages.join('|'), username: username, uid: user.uid },
      })
        .then((response) => {
            console.log(response);

            let responses = response.data.split('\n\n\n\n');

            responses.forEach((res) => {
                props.updateChatMessages(false, res, 'CofounderAI');
            });

            props.handleUpdateChat();

            document.querySelector(".chat-window-section").scrollTop = document.querySelector(".chat-window-section").scrollHeight;
            document.querySelector(".thinking").classList.remove("pulse");
            document.querySelector(".thinking").style.display = "none";

            clearTimeout(timeoutId);
            setmessageWait('');
            resumeApiCall(newMessage, timeoutId);

            setLoading(false);
        })
        .catch((e) => setLoading(false));
    }
  
    function handleSubmit(e) {
      const mainRef = document.querySelector(".center-pane-header");

      if (e.type == 'click') {
          setMessage(e.target.value);
          setHitEnterCount(0);
      }
  
      setTextboxValue(e.target.value, e);
      if ((e.which === 13 || e.keyCode === 13) && e.target.value.trim() != '' && hitEnterCount < 5) {
          setHitEnterCount(hitEnterCount + 1);
          setMessage(e.target.value);
          calculateTextboxRows();
      } else if ((e.which === 13 || e.keyCode === 13) && e.target.value.trim() != '' && hitEnterCount >= 5) {
          inputRef.current.blur();
          setTextboxStyle(50);
          callChatAPI(e.target.value);
          inputRef.current.blur();
          e.target.value = '';
          setTextboxStyle(30);
          setHitEnterCount(0);
      } else {
          setMessage(e.target.value);
          setHitEnterCount(0);
          calculateTextboxRows();
      }
  }
  
    function handleButtonClick(e) {
        var input = document.querySelector(".messageText");
        if (input) {
            callChatAPI(input.value, e);
            setTextboxStyle(30);
            calculateTextboxRows();
            input.value = '';
        }
    }

    function getFirstName() {
      const user = JSON.parse(localStorage['user'])
      if (!user || !user.displayName) {
          return "";
      }

      let words = user.displayName.split(' ');
      let outputString = words[0];
      return outputString;
  }
  
    return (
      <div className="bottomPane" style={style}>
          {loading && <div className='loading'>
              <div style={box}>{messageWait} <span><img style={{ width: 30 }} src = "./loading2.gif" /></span></div>
          </div>}
          <div style= {{ width: "100%" }}>
              <textarea
                ref={inputRef}
                type="text"
                className="messageText"
                placeholder={`Type a message ${getFirstName()}`}
                onClick={handleSubmit}
                onKeyUp={handleSubmit}
                onPaste={handleSubmit}
                style={boxStyle}
              />
              <button style={button} type="submit" onClick={handleButtonClick}>👉🏼</button>
          </div>
      </div>
    );
  };
  
  
  export default MessageBox;