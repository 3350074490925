import React, { useState } from 'react';
import copy from 'copy-to-clipboard';
import Prism from 'prismjs';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import 'prismjs/themes/prism-dark.css';
// import 'prismjs/themes/prism-okaidia.css';
import 'prismjs/components/prism-python.js';
import 'prismjs/components/prism-javascript.js';
import 'prismjs/components/prism-typescript.js';
import 'prismjs/components/prism-css.js';
import 'prismjs/components/prism-csharp';
import 'prismjs/components/prism-cshtml';
import 'prismjs/components/prism-java.js';
import 'prismjs/components/prism-c.js';
import YouTubePlayer from 'react-player/youtube';
import YouTubeVideo from './extensions/YouTubeVideo';
import PlaidButton from './PlaidAPI/PlaidButton';


function ThreadFormat(text, title, indexed, addJob, user, isMobile) {

  const [ show, setShow ] = useState(false);
  const [ showCount, setShowCount ] = useState(0);

  const urlRegex = /(https?:\/\/[^\s()]*)(?=\s|\)|$)/gi;
  const imageRegex = /(https?:\/\/[^\s()]*\.(?:jpg|jpeg|png|gif|bmp|webp))(?=\s|\)|$)/gi;
  const emailRegex = /\b[\w\.-]+@[\w\.-]+\.\w{2,}\b/g;
  const codeBlockRegex = /```(\w+)\n([\s\S]+?)```/gs;
  const markdownUrlRegex = /\[(http(s)?:\/\/[^\]]+)\]\(\1\)/g;
  const linkWithParenthesesRegex = /\[.*?\]\(\s*(https?:\/\/[^\s()]*)\s*\)\.?/gi;
  const YouTubeRegex = /^(?:(?:https?:)?\/\/)?(?:www\.)?(?:m\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))([\w-]{11})(?:\S+)?$/;
  const jobRegex = /%%(.*?)%%/g;
  const executeJobRegex = /##(.*?)##/g;
  const executeFinancesRegex = /\$\$(.*?)\$\$/g;
  const urls = text.match(urlRegex) || [];
  const emails = text.match(emailRegex) || [];
  const codeBlocks = text.match(codeBlockRegex) || [];
  const images = text.match(imageRegex) || [];
  const jobs = text.match(jobRegex) || [];
  const youtubeLinks = text.match(YouTubeRegex);

  let firstImageOrVideo = null; // Store the first image or video URL here

  function toggle() {
      setShow(!show);
  }

  function executeIt() {
    let regex = /JOB\s#(.*?)\s\(/;
    let match = regex.exec(text);

    let start = text.indexOf("**JOB** #") + 9; // "+ 5" to account for the length of "JOB #"
    let end = text.indexOf("(");
    let jobNumber = text.slice(start, end).trim();


    let job_list = [];
    jobs.forEach(job => { 
      const cleanedJob = job.replace(/%%/g, '');
      let o = {
        task: cleanedJob,
        status: "Not Started",
        progress: 0,
        description: `${cleanedJob} on: ${title}`
      }
      job_list.push(o);
    });

    if(job_list.length == 0) {
      let o = {
        task: "Research",
        status: "Not Started",
        progress: 0,
        description: `Researching information on: ${title}`
      }
      job_list.push(o);
    }

     addJob({
          jobNo: jobNumber,
          jobName: "Job " + indexed,
          jobStatus: "Running",
          jobType: "job",
          jobOwner: user,
          jobBackground: text,
          jobTitle: title,
          Jobs: job_list
      });
  }



  let formattedText = text;
  formattedText = text.replace(linkWithParenthesesRegex, (match, url) => url);
  
  console.log(codeBlocks)

  urls.forEach(url => {
    formattedText = formattedText.replace(url, `\n${url}\n\n`);
  });

  images.forEach(image => {
    formattedText = formattedText.replace(image, `\n${image}\n\n`);
  });

  emails.forEach(email => {
    formattedText = formattedText.replace(email, `\n${email}`);
  });

  // jobs.forEach(job => {
  //   const cleanedJob = job.replace(/%%/g, '');
  //   formattedText = formattedText.replace(job, cleanedJob);
  // });

  formattedText = formattedText.replace(markdownUrlRegex, '$1');
  let inCodeBlock = false;
  let language, code;

  const paragraphArray = formattedText.split('\n');
  console.log(paragraphArray);

  const formattedParagraph = paragraphArray.map((line, index) => {
    if (line.match(imageRegex)) {
      if (!firstImageOrVideo){
          firstImageOrVideo = line;
      }

      if (firstImageOrVideo != line) {
          return (
            <div key={index}>
              <img src={line} alt="" style={{ width: '100%', wordWrap: "break-word" }} />
            </div>
          )
      }
    } else if (line.match(jobRegex)) {
      const job = line.match(jobRegex)[0].replace(/%%/g, '');
      return (
        <div key={index} className="get-job">
          {job}
        </div>
      );
    } else if (line.match(executeJobRegex)) {
      const execute = line.match(executeJobRegex)[0].replace(/##/g, '');
      return (
        <div key={index} className={"execute-job execute-job-" + indexed} onClick={executeIt} >
          {execute}
        </div>
      );
    } else if (line.match(executeFinancesRegex)) {
      return (
        <PlaidButton key={index} />
      );

    } else if (line.startsWith('http://') || line.startsWith('https://')) {
      if (line.match(YouTubeRegex)) {
          if (! firstImageOrVideo) {
              firstImageOrVideo = line;
          }
          
          if (firstImageOrVideo != line) {
            return <YouTubeVideo key={index} url = {line} isMobile = {isMobile} videoId={line.match(YouTubeRegex)[1]} />
          }
      } else {
        return (
          <div key={index} style={{ color: '#72aceb' }}>
            <a style={{ color: '#72aceb', wordWrap: "break-word" }} href={line} target='_blank' rel='noopener noreferrer'>
              {line}
            </a>
          </div>
        );
      }
    } else if (line.match(emailRegex)) {
      const email = line.match(emailRegex)[0];
      return (
        <div key={index} style={{ color: '#72aceb' }}>
          <a  style={{ color: '#72aceb', wordWrap: "break-word" }}href={`mailto:${email.trim()}`}>{email.trim()}</a>
        </div>
      );
    } else if (line.startsWith('#')) {
      const titleText = line.substring(1);
      return (
        <div key={index}>
          <strong>{titleText}</strong>
        </div>
      );
    } else if (inCodeBlock) {
      if (line.endsWith('```')) {
        inCodeBlock = false;
        const highlightedCode = Prism.highlight(code, Prism.languages[language], language);
        return (
          <div key={index} style={{ backgroundColor: '#2b2d31', border: '1px solid transparent', borderRadius: '4px', padding: '10px', margin: '10px 0', fontFamily: 'Courier, monospace', position: 'relative' }}>
            <button onClick={() => copy(code)} style={{ position: 'absolute', right: '10px', top: '10px', backgroundColor: '#4CAF50', color: 'white', padding: '5px 10px', borderRadius: '4px', border: 'none', cursor: 'pointer' }}>Copy</button>
            <div style={{ backgroundColor: '#555', color: '#fff', padding: '5px', fontFamily: 'Courier, monospace', borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>{language}</div>
            <pre style={{ backgroundColor: '#2b2d31', color: 'transparent', padding: '10px', margin: '0', fontFamily: 'Courier, monospace', whiteSpace: 'pre-wrap', wordBreak: 'break-all' }} dangerouslySetInnerHTML={{ __html: highlightedCode }} />
          </div>
        );
      } else {
        code += '\n' + line;
        return null;
      }
    } else if (line.startsWith('```')) {
        inCodeBlock = true;
        language = line.slice(3);
        code = '';
        return null;

    } else {
      // Convert **name** to <b>name</b>
      const boldTextRegex = /\*\*(.*?)\*\*/g;
      const formattedLine = line.split(boldTextRegex).map((part, i) => {
        if (i % 2 === 1) {
          // Odd-indexed parts are the **name** sections
          if (part.includes('"')){
            part = part.replace('"', '').replace('"', '');
            return <b key={i} style={{ color: "#FAE69E", fontSize: 20, fontFamily: "'Arial', 'Helvetica', sans-serif" }}>{part}</b>;
          }
          else 
          {
            return <b key={i} style={{ color: "#8a8de3", fontSizze: 14 }}>{part}</b>;
          }
        } else {
          return part;
        }
      });
      
      let showit = true;
      if (!show && showCount > 3) {
          showit = false;
      }

      return (
        <div key={index} className='core-message' style={{ margin: '10px 0', wordWrap: "break-word" }}>
          {showit && formattedLine}
        </div>
      );
    }
  });

  return (
    <>
      {firstImageOrVideo ? (
        firstImageOrVideo.match(imageRegex) ? (
          <div>
            <img src={firstImageOrVideo} alt="" style={{ width: '100%', wordWrap: "break-word" }} />
          </div>
        ) : (
          <YouTubeVideo url={firstImageOrVideo} isMobile={isMobile} videoId={firstImageOrVideo.match(YouTubeRegex)[1]} />
        )
      ) : null}
      {formattedParagraph}
    </>
  );
}

export default ThreadFormat;