import React, { useState, useEffect, useRef } from 'react';
import OptionItem from './OptionItem';
import PlaidButton from './PlaidAPI/PlaidButton';
import GoogleCalendarButton from './google/GoogleCalendarButton';


import packageJson from '../../../package.json';
import axios from 'axios';


const api = axios.create({ "baseURL": packageJson.proxy });

const RightOption = (props) => {
    const [ open, setOpen ] = useState(false);
    const [ selectedId, setSelectedId ] = useState(0);
    const [callbackQueue, setCallbackQueue] = useState(null);
    const [loading, setLoading] = useState(false);
    const [topicsData, setTopicData] = useState({});
    const [topics, setTopics ] = useState([]);
  
    useEffect(() => {
      const fetchData = async () => {
        await getTopics();
      };
      fetchData();
    }, []);

    const style = {
        display: props.open ? 'block' : 'none',
        right: 0,
        top: 0,
        width: 'calc(100% - 50px)',
        maxWidth: 400,
        height: '100%',
        backgroundColor: '#282a2f',
        color: "lightgrey",
        position: "fixed",
        zIndex: 1,
        marginTop: 50,
        marginBottom: 100,
        paddingTop: 50,
        overflowY: "auto"
    }
    
    const getSelectedId = (key, callback) => {
        setSelectedId(prevState => {
          callback(key);
          return key;
        });
    };

    function convertSingleQuotesToDoubleForJSON(input) {
      const output = input.replace(/'(?!')/g, '"').replace(/''/g, "'");
      return output;
    }

    async function getTopics () {  
      if (localStorage['user'] == undefined) {
          setLoading(false);
  
          setTimeout(() => {
            getTopics();
          },  2000);
          return;
      }

      const user = JSON.parse(localStorage['user'])
      document.querySelector(".chat-update").classList.add("pulse");
      // Replace 'api' with the actual API call function.
      api({
          method: 'get',
          url: `/main-topics-get?uid=${user.uid}`,
          headers: {
              'Accept': 'Token',
              'Access-Control-Allow-Origin': '*',
              'Content-Type': 'application/json; charset=UTF-8',
              'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization',
          },
          data: { uid: user.uid },
      })
        .then((response) => {
            const topicData = response.data[0];
            setTopicData(topicData);

            const topicsList = Object.entries(topicData.topics)
              .sort(([, aValue], [, bValue]) => bValue - aValue)
              .map(([key, value]) => ({ [key]: value }));

            setTopics(topicsList);      
            document.querySelector(".chat-update").classList.remove("pulse");
        })
        .catch((e) => { setLoading(false); });
    }


    async function getChatsByTopic (e) {  
      if (localStorage['user'] == undefined) {
          setLoading(false);
  
          setTimeout(() => {
            getTopics();
          },  2000);
          return;
      }

      if (!e || !e.target) {
          return;
      }

      let topic = e.target.getAttribute('topic');

      if (!topic) {
          return;
      }

      const user = JSON.parse(localStorage['user'])
      document.querySelector(".chat-update").classList.add("pulse");
      // Replace 'api' with the actual API call function.
      api({
          method: 'get',
          url: `/main-chat-get-topics?uid=${user.uid}&topic=${topic}`,
          headers: {
              'Accept': 'Token',
              'Access-Control-Allow-Origin': '*',
              'Content-Type': 'application/json; charset=UTF-8',
              'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization',
          },
          data: { uid: user.uid, topic: topic },
      })
        .then((responses) => {
            const topicData = responses.data;
            console.log(topicData);
            props.setMessages([], () => { });
            topicData.forEach((res) => {
                let name = res.agent ? 'CofounderAI' : user.displayName;
                setTimeout(() => {
                    props.updateChatMessages(!res.agent, res.message, name, res.timestamp, res.id, res.messageBase, res.title, res.topic );
                }, 20);            
            });

            props.snavigateToLastMessage()
            
            document.querySelector(".chat-update").classList.remove("pulse");
        })
        .catch((e) => { setLoading(false); });
    }

    return (
        <div style={style}>
          <div style={{ marginLeft: 20, marginRight: 20 }}>
            <PlaidButton />
          </div>
          <div style={{ marginLeft: 20, marginRight: 20 }}>
            <GoogleCalendarButton />
          </div>
          <div className="inquiries" style = {{ marginTop: 30 }}>
              {topics.map((x, index) => {
                  const key = Object.keys(x)[0];
                  const value = Object.values(x)[0];
                  return <div style={{ marginLeft: 20, marginRight: 20, fontWeight: "bold" }}>
                      <div className = "newChat" key={index} topic={key} onClick={getChatsByTopic}>{`${key}: ${value}`}</div>
                  </div>
              })}
          </div>
      </div>
    );
};


export default RightOption;