import React, { useState, useEffect, useRef } from 'react';
import ActivityBar from './ActivityBar';
import PlaidButton from './PlaidAPI/PlaidButton';
import GoogleCalendarButton from './google/GoogleCalendarButton';

import packageJson from '../../../package.json';
import axios from 'axios';


const api = axios.create({ "baseURL": packageJson.proxy });

const LeftPane = (props) => {
    const inputRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [topicsData, setTopicData] = useState({});
    const [topics, setTopics ] = useState([]);
  
    useEffect(() => {
      const fetchData = async () => {
        await getTopics();
      };
      fetchData();
    }, []);


    const style = {

    }

    function convertSingleQuotesToDoubleForJSON(input) {
      const output = input.replace(/'(?!')/g, '"').replace(/''/g, "'");
      return output;
    }

    async function getTopics () {  
      if (localStorage['user'] == undefined) {
          setLoading(false);
  
          setTimeout(() => {
            getTopics();
          },  2000);
          return;
      }

      const user = JSON.parse(localStorage['user'])
      document.querySelector(".chat-update").classList.add("pulse");
      // Replace 'api' with the actual API call function.
      api({
          method: 'get',
          url: `/main-topics-get?uid=${user.uid}`,
          headers: {
              'Accept': 'Token',
              'Access-Control-Allow-Origin': '*',
              'Content-Type': 'application/json; charset=UTF-8',
              'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization',
          },
          data: { uid: user.uid },
      })
        .then((response) => {
            const topicData = response.data[0];
            setTopicData(topicData);

            const topicsList = Object.entries(topicData.topics)
              .sort(([, aValue], [, bValue]) => bValue - aValue)
              .map(([key, value]) => ({ [key]: value }));

            setTopics(topicsList);      
            document.querySelector(".chat-update").classList.remove("pulse");
        })
        .catch((e) => { setLoading(false); });
    }


    async function getChatsByTopic (e) {  
      if (localStorage['user'] == undefined) {
          setLoading(false);
  
          setTimeout(() => {
            getTopics();
          },  2000);
          return;
      }

      if (!e || !e.target) {
          return;
      }

      let topic = e.target.getAttribute('topic');

      if (!topic) {
          return;
      }

      const user = JSON.parse(localStorage['user'])
      document.querySelector(".chat-update").classList.add("pulse");
      // Replace 'api' with the actual API call function.
      api({
          method: 'get',
          url: `/main-chat-get-topics?uid=${user.uid}&topic=${topic}`,
          headers: {
              'Accept': 'Token',
              'Access-Control-Allow-Origin': '*',
              'Content-Type': 'application/json; charset=UTF-8',
              'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization',
          },
          data: { uid: user.uid, topic: topic },
      })
        .then((responses) => {
            const topicData = responses.data;
            console.log(topicData);
            props.setMessages([], () => { });
            topicData.forEach((res) => {
                let name = res.agent ? 'CofounderAI' : user.displayName;
                setTimeout(() => {
                    props.updateChatMessages(!res.agent, res.message, name, res.timestamp, res.id, res.messageBase, res.title, res.topic );
                }, 20);            
            });

            props.snavigateToLastMessage()
            
            document.querySelector(".chat-update").classList.remove("pulse");
        })
        .catch((e) => { setLoading(false); });
    }
  
    return (
      <div className="leftPane" style={style}>
          <ActivityBar topics = {props.topics} callChatAPI={props.callChatAPI}  />
          <div className='left-inner-pane'>
              <div className="top-chat-section">Chat</div>
              <div className="newChat" style={{ marginBottom: 10, textAlign: 'center' }} onClick={props.handleNewChat}>
                +  New Chat
              </div>
              
              <PlaidButton />
              <GoogleCalendarButton/>
              <div className="inquiries" style = {{ marginTop: 30 }}>
                  {topics.map((x, index) => {
                      const key = Object.keys(x)[0];
                      const value = Object.values(x)[0];
                      return <div className = "newChat" key={index} topic={key} onClick={getChatsByTopic}>{`${key}: ${value}`}</div>
                  })}
              </div>
          </div>
      </div>
    );
  };
  
  
  export default LeftPane;