import React, { useState, useEffect, useRef } from 'react';
import LeftOption from './LeftOption';
import RightOption from './RightOption';

const Header = (props) => {
    const [ open, setOpen ] = useState(false);
    const [ openRight, setOpenRight ] = useState(false);

    let selected = props.selected;

    let color = "white";
    let objectColor = "transparent";
    let childColor = "";

    if (selected) {
       color = "#444791";
       objectColor = "#444791";
       childColor = "";
    }

    const style = {
        display: 'inline-flex',
        zIndex: 9000,
    }

    function getFirstLetters(inputString) {
      let words = inputString.split(' ');
      let outputString = '';
      for(let i = 0; i < words.length; i++) {
          if(words[i] !== '') {
              outputString += words[i][0].toUpperCase();
          }
      }
      return outputString;
    }

    function getProfile() {
      if (localStorage['user'] == undefined) {
          return getFirstLetters(props.user)
      }
      else
      {
          const user = JSON.parse(localStorage['user']);
          return <img src={user.photoURL} style={{ position: "absolute", top: 0, right: 0, width: 35, height: 35, borderRadius: "50%", backgroundColor: "#fff", color: "#444791", textAlign: "center", fontSize: 12, fontWeight: "bold" }} />
      }
    }

    function toogleOpen() {
        setOpen(!open);
        setOpenRight(false);
    }

    function toogleRightOpen() {
        setOpenRight(!openRight);
        setOpen(false);
    }

    useEffect(() => {
      props.setSideBar(() => {
        if (open) {
            setOpen(false);
        }
        
        if (openRight) {
            setOpenRight(false);
        }
      });
  }, [props.setSideBar]);

    return (
      <div className='header-pane' style={style}>
          <LeftOption callChatAPI={props.callChatAPI} setOpen ={setOpen} open={open} />
          <RightOption 
              open={openRight} 
              updateChatMessages = { props.updateChatMessages }
              handleNewChat = { props.handleNewChat } 
              navigateToLastMessage = { props.navigateToLastMessage }
              setMessages = { props.setMessages }
          />
          <div style={{ minWidth: 68, padding: 20, paddintTop: 10, color: "#fff", fill: "#fff", textAlign: "left",position: "absolute", left: 0, top: -5 }} onClick={toogleOpen} >
            <svg stroke="currentColor" fill="none" stroke-width="1.5" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" class="h-6 w-6" style = {{ width: "1.5rem", height: "1.5rem" }} height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg>
          </div>
          <div style={{ minWidth: 320, maxWidth: 320, fontSize: 14, paddintTop: 15, color: "#fff", textWeight: "1000", padding: 10, textAlign: "left", fontWeight: "bold", fontSize: 18 }}>
            
            <span className='jobs-tab' onClick={props.handleShowJobsMobile} >{props.jobHeader}</span>
          </div>
          <div className='search-bar' style = {{ width: "80%" }}>
              <input type="text" placeholder="Search" style={{ width: "calc(70%)", height: 30, borderRadius: 5, border: "none", padding: 5, paddingLeft: 30, fontSize: 12, marginLeft: -200, backgroundColor: "rgb(57, 59, 64)", color: "#fff"}}
              placeHolder = "Search any past ideas..." />
          </div>
          <div style={{ position: "absolute", right: 20, padding: 10, paddingLeft: 0, paddingRight: 0 }}>
              <div style={{ paddingTop: 10, width: 35, height: 35, borderRadius: "50%", backgroundColor: "#fff", color: "#444791", textAlign: "center", fontSize: 12, fontWeight: "bold", position: "relative" }}  onClick= {toogleRightOpen}>
                {getProfile()}
              </div>
          </div>
      </div>
    );
  };
  
  
  export default Header;