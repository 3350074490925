import React, { useState, useEffect, useRef } from 'react';
import Threads from './Threads';
import Thread from './Thread';
import MessageBox from './MessageBox';

const CenterPane = (props) => {
    const chat_messages = props.messages;

    const [ internalWidth, setInternalWidth ] =  useState(230);

    let style = {
        display: !props.showJobsMobile ? 'block' : 'none'
    }

    if (!props.isMobile) {
        style = {
            display: 'block'
        }
    }

    let width = "100%";
    let left = "0%"
    if (props.isMobile) {
        width = "100%";
        left = "0%"
    }

    const chatheader = {
        // borderBottom: '1px solid rgb(52, 52, 52)',
        width: "100%",
        position: "absolute",
        top: 0,
        paddingTop: 20,
        padding: 20,
        fontWeight: "bold",
        fontSize: 20,
        right: 0,
        textAlign: "right",
        backgroundColor: "transparent",
        zIndex: 1
    }

    let chatWindow = {
        backgroundColor: "transparent",
        marginTop: 0,
        marginBottom: 110,
        minHeight: "calc(87vh - 30px)",
        overflowY: "auto",
        overflowX: "hidden",
        position: "relative",
        marginRight: 'auto',
        marginLeft: 'auto',
    }

    chatWindow.minHeight = "calc(100vh - 50px)";
    if (props.isMobile) {
        chatWindow.minHeight = "calc(100vh - 50px)";
    }

    const chatStyle = {
        width: "100%",
        height: "100%",
        fontWeight: "bold", 
        color:"#383a40",
        fontSize: 50,
        display: "flex",
        flexDirection: "column", // Added this to stack elements vertically
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        position: "absolute",
    }

    const chatContainerRef = useRef(null);

    useEffect(() => {
        scrollToBottom();

        //alert(JSON.stringify(chat_messages))

        window.addEventListener('orientationchange', function() {
            scrollToBottom();
            setTimeout(() => {
                scrollToBottom();
            }, 100);
        });

        document.addEventListener('resize', () => {
            scrollToBottom();
            setTimeout(() => {
                scrollToBottom();
            }, 100);
         });
    }, [chat_messages]);

    useEffect(() => {
        if (props.loading || (! props.isSPA && props.device == "iPhone")) {
            setInternalWidth(176)
        }
        else 
        {
            setInternalWidth(176);
        }        
    })

    const scrollToBottom = () => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollIntoView({ behavior: 'smooth' });
            document.querySelector(".chat-window-section").scrollTop = document.querySelector(".chat-window-section").scrollHeight;
        }
    };

    function getFirstName() {
        const user = JSON.parse(localStorage['user'])
        if (!user || !user.displayName) {
            return "";
        }

        let words = user.displayName.split(' ');
        let outputString = words[0];
        return outputString;
    }

    function addNewJob() {
        props.addJob({
            jobNo: "5kR93cB3S94L$g4h",
            jobName: "Job 1",
            jobStatus: "Running",
            jobType: "Chat",
            jobOwner: "John Doe",
            Jobs: [
                {
                    task: "Setup a flight to New York",
                    status: "Running",
                    progress: 0.5,
                    description: "Setup a flight so that Frank can be in new your by 5pm",
                },
                {
                    task: "Apply for a new job",
                    status: "Running",
                    progress: 0.2,
                    description: "Apply for a new job for Frank for an AI engineer"
                }
            ]
        });
    }

    function closeSideBars() {
        props.closeSideBars();
    }
  
    return (
      <div className='centerPane' style={style} onClick={closeSideBars}>
          <div className="center-pane-header" style={chatheader}>
             
            <span className='new-tab' onClick={props.handleNewChat} >+</span>
            <span className='thinking' onClick={props.handleUpdateChat} ><img style={{ width: 20 }} src = "./loading2.gif" /></span>
            <span className='refresh-tab' onClick={props.handleUpdateChat} ><span className='chat-update'>💬</span></span>
          </div>
          <div style={chatWindow} ref={chatContainerRef}>
              <div className="chat-window-section" style={{ position: "absolute", flexDirection: "column-reverse", top: 0, height: `calc(100vh - ${internalWidth}px)`, width: width, overflowX: "hidden", overflowY: "auto", left: left }}>
                  {chat_messages.map((chat, index) => {
                      return <Thread key={index} index={index} chat={chat} user={chat.user} currentUser={chat.isUser} timestamp = {chat.timestamp} addJob={props.addJob} isMobile={props.isMobile} 
                                handleUpdateChat = {props.handleUpdateChat} >
                          {chat.message}
                      </Thread>
                    })}

                   {chat_messages.length == 0 && <div className='no-chat' style={chatStyle}>Cofounder</div>  }
              </div>
          </div>
          <MessageBox user={props.user} messages = {props.messages} handleUpdateChat = {props.handleUpdateChat} updateChatMessages= {props.updateChatMessages} handleSearch={props.handleSearch} setSearchQuery = {props.setSearchQuery} inputRef = {props.inputRef} phoneType = {props.phoneType}  />
      </div> 
    );
};
  
  
  export default CenterPane;