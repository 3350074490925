import React, { useState, useEffect, useRef } from 'react';
import { w3cwebsocket as W3CWebSocket } from 'websocket';
import './App.css';
import packageJson from '../../../package.json';
import axios from 'axios';
import LeftPane from './LeftPane';
import CenterPane from './CenterPane';
import RightPane from './RightPane';
import MessageBox from './MessageBox';
import Header from './Header';
import { json } from 'react-router-dom';
import Splash from './Splash';
import SplashRefresh from './SplashRefresh';

const client = new W3CWebSocket('ws://localhost:8000');
const api = axios.create({ "baseURL": packageJson.proxy });


const CofounderAI = () => {
  const [jobs, setJobs] = useState([]);
  const [jobStaged, setJobStaged] = useState([]);
  const [centerPaneData, setCenterPaneData] = useState([]);
  const [jobHeader, setJobHeader ] = useState("Jobs");
  const [showJobsMobile, setShowJobsMobile] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [topics, setTopics] = useState([]);
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [device, setDevice] = useState('broswer');
  const [isSPA, setIsSPA] = useState(false);
  const [sideBar, setSideSideBar] = useState(false);
  const [user, setUser] = useState(JSON.parse(localStorage['user']).displayName);

    // setTimeout(() => {
    //   setLoading(false);
    // },  1000); 


  const inputRef = useRef(null);


  function isMobileDevice() {
    return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
  };

  useEffect(() => {
    phoneType();

    document.addEventListener('resize', () => {
      phoneType()

      const width = window.screen.width;
      if (width < 1100) {
        setIsMobile(true);
        setDevice("iPhone");
      }
    });

    if (isMobile && window.screen.orientation) {
        window.screen.orientation.lock('portrait');
    }

  }, []);

  const closeSideBars = () => {
      setSideBar(sideBar);
  }


  const setSideBar = (call) => {
    if (call) {
        setSideSideBar(call);
        return call;
    }
    else
    {   setSideSideBar(null);
        return null;
    }
  }



  const phoneType = () => {
      let isLocalSPA = false;
      if (window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone === true) {
          isLocalSPA = true;
      }  

      // get the width of the screen
      const width = window.screen.width;

      // Check if the user agent contains 'iPhone'
      const isiPhone = /iPhone/i.test(navigator.userAgent);

      // Check if the user agent contains 'Android'
      const isAndroid = /Android/i.test(navigator.userAgent);

      // Check if the user agent contains 'Mobile Safari' (iPhone browser)
      const isiPhoneBrowser = isiPhone && /Mobile Safari/i.test(navigator.userAgent);

      // Check if the user agent contains 'Android' and 'Version/' (Android browser)
      const isAndroidBrowser = isAndroid && /Version\//i.test(navigator.userAgent);

      // If none of the above, consider it as a regular computer browser
      const isComputerBrowser = !(isiPhone || isAndroid || isiPhoneBrowser || isAndroidBrowser);

      let deviceType = 'browser';
      if (isiPhone) {
        deviceType = 'iPhone';
        setIsMobile(true);
      } else if (isAndroid) {
        deviceType = 'Android';
        setIsMobile(true);
      } else if (isiPhoneBrowser) {
        deviceType = 'iPhoneBrowser';
        setIsMobile(true);
      } else if (isAndroidBrowser) {
        deviceType = 'AndroidBrowser';
        setIsMobile(true);

      } else {
        deviceType = 'browser';
        setIsMobile(false);
      }

      if (width < 1100) {
        setIsMobile(true);
      }


      setIsSPA(isLocalSPA)
      setDevice(deviceType);

      return deviceType;
  }


  const getChats = (refresh) => {  
      
      if (refresh) {
        //setRefresh(true);
      }
      else
      {
        setLoading(true);
      }

      if (localStorage['user'] == undefined) {
          setLoading(false);
          setRefresh(false);

          setTimeout(() => {
              getChats();
          },  2000);
          return;
      }

      const user = JSON.parse(localStorage['user'])
      document.querySelector(".chat-update").classList.add("pulse");
      // Replace 'api' with the actual API call function.
      api({
        method: 'post',
        url: `/main-chat-get?uid=${user.uid}`,
        headers: {
          'Accept': 'Token',
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json; charset=UTF-8',
          'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization',
        },
        data: { uid: user.uid },
      })
        .then((response) => {

          let responses = response.data.sort((a, b) => {
            return new Date(a.timestamp) - new Date(b.timestamp);
          });

          console.log(responses);
          responses.forEach((res) => {
              let name = res.agent ? 'CofounderAI' : user.displayName;
              setMessages([], () => { });
              setTimeout(() => {
                //alert(JSON.stringify(res));
                updateChatMessages(!res.agent, res.message, name, res.timestamp, res.id, res.messageBase, res.title, res.topic );
              }, 20);
              
              navigateToLastMessage()
          });
          setLoading(false);

          document.querySelector(".chat-update").classList.remove("pulse");
        })
        .catch((e) => { setLoading(false); });
  }


  const updateChatMessages = (isUser, message, user, timestamp, id, messageBase, title, topic) => {
    let tstamp = new Date();
    if (timestamp != null) {
        tstamp = new Date(timestamp);
    }

    let newMessage = { message: message, isUser: isUser, user: user, timestamp: tstamp, id: id, messageBase: messageBase, title: title, topic: topic };
    setMessages((messages) => [...messages, newMessage]);
    setTimeout(() => {
        navigateToLastMessage();
        //document.querySelector(".chat-window-section").scrollTop = document.querySelector(".chat-window-section").scrollHeight;
    }, 20);
  }

  const navigateToLastMessage = () => {
      const lastMessageElement = document.querySelector('.message:last-child');
      if (lastMessageElement) {
        document.querySelector(".chat-window-section").scrollTop = lastMessageElement.offsetTop;
      }
  }


  const updateCofounderUserName = (user) => {
    if (user) {
      setUser(user);
    }
  }

  const addJob = (job) => {
      if (jobStaged != undefined && !jobStaged.includes(job.jobNo)) {
          setJobStaged(job.jobNo);
          setJobs((jobs) => [...jobs, job]);   
      }

      handleShowJobsMobile();
  } 

  const handleSearch = (e) => {
      e.preventDefault();
      client.send(JSON.stringify({ query: searchQuery }));
  };

  const handleNewChat = () => {
      setCenterPaneData([]);
      setMessages([]);
      setMessage('');
  };

  const handleUpdateChat = () => {
      getChats(true);
  };

  const handleShowJobsMobile = () => {
      setShowJobsMobile(!showJobsMobile);
      if (showJobsMobile) {
          setJobHeader("Jobs");
      }
      else {
          setJobHeader("Chat");
      } 
  };

  
  function callChatAPI(newMessage) {  
      const user = JSON.parse(localStorage['user'])
      const username = user.displayName;
      //document.querySelector(".chat-update").classList.add("pulse"); //refresh-tab-color
      document.querySelector(".thinking").classList.add("refresh-tab-color");
      document.querySelector(".thinking").style.display = "inline-flex";

      setTimeout(() => {
        document.querySelector(".thinking").classList.remove("refresh-tab-color");
        document.querySelector(".thinking").style.display = "none";
      }, 80000)

      api({
        method: 'post',
        url: `/main-chat?username=${username}&messages=${newMessage}&uid=${user.uid}&save=false`,
        headers: {
          'Accept': 'Token',
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization',
        },
        data: { messages: newMessage, username: username, uid: user.uid, save: false },
      })
      .then((response) => {
          handleNewChat();
          updateChatMessages(true, newMessage, username);
          console.log(response);
          let responses = response.data.split('\n\n\n\n');

          responses.forEach((res) => {
              updateChatMessages(false, res, 'CofounderAI');
          });

          document.querySelector(".chat-window-section").scrollTop = document.querySelector(".chat-window-section").scrollHeight;
         // document.querySelector(".chat-update").classList.remove("pulse");
          document.querySelector(".thinking").classList.remove("refresh-tab-color");
          document.querySelector(".thinking").style.display = "none";
        })
        .catch((e) => {});
    }

  return (
    <div className="AppCounder">
      {loading && <Splash />}
      {refresh && <SplashRefresh />}

      <Header 
          user={user} 
          handleNewChat = {handleNewChat} 
          phoneType={phoneType}
          callChatAPI={callChatAPI}
          handleShowJobsMobile = {handleShowJobsMobile}
          setSideBar = {setSideBar}
          jobHeader = {jobHeader}
          isMobile = {isMobile}
          updateChatMessages = {updateChatMessages} 
          navigateToLastMessage = {navigateToLastMessage}
          setMessages = {setMessages }
          />
      <LeftPane 
          callChatAPI={callChatAPI}
          updateChatMessages = {updateChatMessages}
          handleNewChat = {handleNewChat} 
          navigateToLastMessage = {navigateToLastMessage}
          setMessages = {setMessages }
          topics = {topics} />
      <CenterPane 
          user={user} 
          centerPaneData={centerPaneData} 
          messages = {messages} 
          newMessage={message} 
          updateChatMessages= {updateChatMessages} 
          handleSearch={handleSearch} 
          handleNewChat = {handleNewChat} 
          handleUpdateChat = {handleUpdateChat}
          setSearchQuery = {setSearchQuery} 
          closeSideBars = {closeSideBars}
          inputRef = {inputRef} 
          phoneType={phoneType} 
          addJob ={addJob} 
          loading = {loading ?? refresh}
          showJobsMobile={showJobsMobile}
          isMobile = {isMobile}
          device = {device}
          isSPA = {isSPA}
        />
      <RightPane 
          jobs={jobs} 
          showJobsMobile={showJobsMobile} 
          isMobile = {isMobile} 
          />
    </div>
  );
};

export default CofounderAI;